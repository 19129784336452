<template>
  <loader
    v-if="!$root.homeApiCall"
    loading-text="Please Wait, Loading Content"
    inner-class="{color: red,'text-align': center,padding: 25px,position: relative,top: 175px,left: 10px}"
  />
  <div v-else-if="$root.homeApiCall">
    <div class="container-main">
      <div class="puroshkar">
        <h2 class="pre-1">
          <img :src="$root.tounamentHeaderImage.thumbnail_path" />
        </h2>
        <Title
          :title="$root.tounamentHeaderImage.title"
          textShadow="font-shadow"
        />
        <separaror innerClass="seperator" />
        <TournamentList
          :tournaments="tournaments"
          @enroll="enroll($event)"
          state="Home"
          inner="inner"
          runningTournament="running-trournament"
          :lastRegistrationTime="ttTime"
        />
      </div>
    </div>
    <div class="container-main">
      <Testimonial :testimonials="testimonials" />
    </div>
    <GameRegistration
      @registration="registration($event)"
      @buttonStatus="changeDisableStatus($event)"
      :errorMessage="message"
      :disabled="disabled"
      :button="button"
    />
    <Otp
      @resendCode="resendCode()"
      @otpVerification="otpVerification($event)"
      @buttonStatus="changeDisableStatus($event)"
      :otpAttemptRemaining="otpAttemptRemaining"
      :errorMessage="message"
      :disabled="disabled"
      :button="button"
      :isUserSubscribed="isUserSubscribed"
      :username="name"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Testimonial from "@/components/Testimonial";
import Separaror from "@/components/Separaror";
//import TextContainer from "@/components/TextContainer";
import TournamentList from "@/components/TournamentList";
import Title from "@/components/Title";
import GameRegistration from "@/components/GameRegistration";
import Loader from "@/components/Loader";
import Otp from "@/components/Otp";
import popup from "@/js/popup";
import helper from "@/api/helper";

export default {
  name: "Home",
  components: {
    Testimonial,
    Separaror,
    //TextContainer,
    TournamentList,
    Title,
    GameRegistration,
    Otp,
    loader: Loader
  },
  data: () => {
    return {
      ttTime: 600,
      button: true,
      disabled: true,
      error: false,
      message: "",
      tournaments: [],
      testimonials: [],
      otpAttemptRemaining: 10,
      packageId: 0,
      tournamentId: 0,
      msisdn: "",
      name: "",
      isUserSubscribed: false
    };
  },
  async created() {
    try {
      let userLocalStorageData = this.$root.get_localstorage_user_data(
        "userInfo"
      );
      let { msisdn } = userLocalStorageData;
      const host = this.$root.url();
      const path = "api/v1/hullor/game";
      const url = `${host}/${path}`;
      let data = { app_type: "wap", msisdn: msisdn };
      let response = await axios.post(url, data, {
        headers: {
          token: "f3827eeb536655b578de9aaeb53acf73"
        }
      });
      this.$root.homeApiCall = true;
      if (response.data.status === "SUCCESS") {
        this.tournaments = response.data.tournaments;
        this.testimonials = response.data.testimonials;
        this.$root.translation = response.data.translation;
        //console.log();
        let {
          BANNER,
          REWARD,
          RULE,
          CONTAIN_IMAGE,
          GAME_RULE_IMAGE,
          TOURNAMENT_HEADER_IMAGE,
          STAR_TESTIMONIAL,
          LOGO,
          FOOTER_LOGO
        } = response.data.allImages;
        this.$root.bannerImage(BANNER, "banners");
        this.$root.bannerImage(REWARD, "rewards");
        this.$root.bannerImage(RULE, "rules");
        this.$root.bannerImage(CONTAIN_IMAGE, "containImage", false);
        this.$root.bannerImage(GAME_RULE_IMAGE, "gameRuleImage", false);
        this.$root.bannerImage(
          TOURNAMENT_HEADER_IMAGE,
          "tounamentHeaderImage",
          false
        );
        this.$root.bannerImage(STAR_TESTIMONIAL, "testimonailImage", false);
        this.$root.bannerImage(LOGO, "logo", false);
        this.$root.bannerImage(FOOTER_LOGO, "footer_logo", false);
      }
    } catch (e) {
      this.$root.homeApiCall = true;
      this.error = true;
      this.message = "Something wrong, Please try again later";
      console.log(e);
    }
  },
  mounted() {
    this.timer();
  },
  methods: {
    errorMessage(error) {
      this.error = error.error;
      this.message = error.message;
    },
    timer() {
      this.sleep(1000);
    },
    sleep(ms) {
      let vm = this;
      return setInterval(function() {
        vm.timeChange(vm.tournaments, ms);
      }, ms);
    },
    timeChange(tournaments = [], ms = 1000) {
      let s = ms / 1000;
      for (let index in tournaments) {
        if (
          tournaments[index].startDateOfTournamentInSec > 0 ||
          tournaments[index].endDateOfTournamentInSec > 0 ||
          tournaments[index].tournamentEndInSecAgo > 0
        ) {
          if (tournaments[index].tournamentStatus === "notStarted") {
            tournaments[index].startDateOfTournamentInSec =
              tournaments[index].startDateOfTournamentInSec - s;
          } else if (tournaments[index].tournamentStatus === "running") {
            tournaments[index].endDateOfTournamentInSec =
              tournaments[index].endDateOfTournamentInSec - s;
          } else if (tournaments[index].tournamentStatus === "finished") {
            tournaments[index].tournamentEndInSecAgo =
              tournaments[index].tournamentEndInSecAgo + s;
          }
        }
      }
    },
    async enroll(data = {}) {
      let msg = "",
        notification = "";
      let { packageId, tournamentId, id, index } = data;
      console.log(`index ${index}`);
      this.packageId = packageId;
      this.tournamentId = tournamentId;
      let userLocalStorageData = this.$root.get_localstorage_user_data(
        "userInfo"
      );
      let { msisdn } = userLocalStorageData;
      if (msisdn === undefined) {
        popup.showModal(id);
        return true;
      } else {
        try {
          const host = this.$root.url();
          const path = "api/v1/hullor/subscriber/directPackagePurchase";
          const url = `${host}/${path}`;
          let data = {
            app_type: "wap",
            msisdn: msisdn,
            packageId: packageId,
            tournamentId: tournamentId
          };
          let response = await axios.post(url, data, {
            headers: {
              token: "f3827eeb536655b578de9aaeb53acf73"
            }
          });
          let { status, message } = response.data;
          if (status === "SUCCESS") {
            if (response.data.tournamentType === "FREE") {
              notification = "success";
              msg = `Successfully subscribed ${this.tournaments[index].title} tournament`;
              this.$router.push({ name: "Enrolled" });
            } else {
              window.location.href = response.data.payment_redirect_link;
            }
            return true;
          } else {
            msg = message;
            notification = "warning";
            if (response.data.code === 3000) {
              // code = 3000 means user already purchase package
              return this.$router.push("enrolled");
            }
          }
        } catch (e) {
          console.log("catch block ", e);
          msg = e.message;
          notification = "warning";
          return true;
        } finally {
          if (!helper.empty_checker(notification)) {
            helper.pop_up(msg, notification, 4000);
          }
        }
      }
    },
    changeDisableStatus(data = {}) {
      let { isDisable } = data;
      this.disabled = isDisable;
      console.log("BUTTON FINAL DISABLED STATUS :", this.disabled);
    },
    async registration(userInfo = {}) {
      try {
        this.button = false;
        console.log("userInfo ", userInfo);
        let { msisdn } = userInfo;
        //this.name = name;
        const host = this.$root.url();
        const path = "api/v1/hullor/subscriber/registration";
        const url = `${host}/${path}`;
        let data = {
          app_type: "wap",
          msisdn: msisdn
        };
        let response = await axios.post(url, data, {
          headers: {
            token: "f3827eeb536655b578de9aaeb53acf73"
          }
        });
        console.log("response ", response);
        if (response.data.status === "SUCCESS") {
          this.isUserSubscribed = response.data.isUserSubscribed;
          this.name = response.data.username;
          popup.hideModal("regModal");
          popup.showModal("otpModal");
          this.otpAttemptRemaining = response.data.otpAttemptRemaining;
          let errorObject = { error: false, message: null };
          this.errorMessage(errorObject);
          this.msisdn = response.data.msisdn;
        } else {
          let errorObject = { error: true, message: response.data.message };
          this.errorMessage(errorObject);
        }
        this.button = true;
        return true;
      } catch (e) {
        this.button = true;
        console.log("catch block ", e);
        let errorObject = {
          error: true,
          message: "Something wrong,Please try again later"
        };
        this.errorMessage(errorObject);
        return true;
      } finally {
        this.disabled = true;
      }
    },
    async resendCode() {
      try {
        this.button = false;
        const host = this.$root.url();
        const path = "api/v1/hullor/subscriber/resetOtp";
        const url = `${host}/${path}`;
        let data = {
          app_type: "wap",
          msisdn: this.msisdn
        };
        let response = await axios.post(url, data, {
          headers: {
            token: "f3827eeb536655b578de9aaeb53acf73"
          }
        });
        console.log("response ", response);
        if (response.data.status === "SUCCESS") {
          this.otpAttemptRemaining = response.data.otpAttemptRemaining;
          let errorObject = { error: false, message: response.data.message };
          this.errorMessage(errorObject);
        } else {
          let errorObject = { error: true, message: response.data.message };
          this.errorMessage(errorObject);
        }
        this.button = true;
        return true;
      } catch (e) {
        this.button = true;
        console.log("catch block ", e);
        let errorObject = {
          error: true,
          message: "Something wrong,Please try again later"
        };
        this.errorMessage(errorObject);
        return true;
      }
    },
    async otpVerification(otpInfo = {}) {
      let msg = "",
        notification = "";
      try {
        this.button = false;
        console.log("userInfo ", otpInfo);
        let { otp, name } = otpInfo;
        const host = this.$root.url();
        const path = "api/v1/hullor/subscriber/otpVerification";
        const url = `${host}/${path}`;
        name = this.name ? this.name : name;
        let data = {
          app_type: "wap",
          msisdn: this.msisdn,
          name: name,
          otp: otp,
          packageId: this.packageId,
          tournamentId: this.tournamentId
        };
        let response = await axios.post(url, data, {
          headers: {
            token: "f3827eeb536655b578de9aaeb53acf73"
          }
        });
        console.log("response ", response);
        let { status, message } = response.data;
        if (status === "SUCCESS") {
          let userInfo = { msisdn: this.msisdn };
          this.$root.set_localstorage_user_data(userInfo);
          popup.hideModal("otpModal");
          if (response.data.tournamentType === "FREE") {
            notification = "success";
            msg = `Successfully subscribed selected tournament`;
            this.$router.push({ name: "Enrolled" });
          } else {
            window.location.href = response.data.payment_redirect_link;
          }
        } else {
          msg = message;
          notification = "warning";
          //popup.hideModal("otpModal");
          if (response.data.code === 3000) {
            // code = 3000 means user already purchase package
            let userInfo = { msisdn: this.msisdn };
            this.$root.set_localstorage_user_data(userInfo);
            return this.$router.push("enrolled");
          }
          let errorObject = { error: true, message: response.data.message };
          this.errorMessage(errorObject);
        }
        this.button = true;
        return true;
      } catch (e) {
        this.button = true;
        console.log("catch block ", e);
        msg = e.message;
        notification = "warning";
        let errorObject = {
          error: true,
          message: "Something wrong,Please try again later"
        };
        this.errorMessage(errorObject);
        return true;
      } finally {
        if (!helper.empty_checker(notification)) {
          helper.pop_up(msg, notification, 4000);
        }
      }
    }
  }
};
</script>

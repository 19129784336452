<template>
  <div class="puroshkar">
    <h2 class="pre-1"><img :src="$root.testimonailImage.thumbnail_path" /></h2>
    <Title :title="$root.testimonailImage.title" textShadow="font-shadow" />
    <separaror innerClass="seperator" />
    <div class="puroshkar-lists">
      <div class="testi-slider-large">
        <div
          class="testi-slider"
          v-for="testimonial in testimonials"
          :key="testimonial.id"
        >
          <img :src="testimonial.thumbnail_path" />
          <h3 class="not-bld">{{ testimonial.name }}</h3>
          <h4>{{ testimonial.message }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import Separaror from "@/components/Separaror";
export default {
  name: "Testimonial",
  props: ["testimonials"],
  components: {
    Title,
    Separaror
  }
};
</script>

<style scoped></style>
